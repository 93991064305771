import * as dompack from "dompack";
import * as mso from "@mod-mso/webdesigns/mso/mso";
// import '@mod-mso_templates/webdesigns/pretium/pretium';
// import './widgets/widgets';
import * as whintegration from "@mod-system/js/wh/integration";
import "./widgets/ovcarousel/ovcarousel";
import "./widgets/ctacarousel/ctacarousel";

import "./components/header/header";
import "./components/overlaymenu/overlaymenu";

import "@mod-nubiumreviews/webcomponents";

import "./nubium.scss";

dompack.onDomReady(() => {
  new mso.MSOSite();

  if (whintegration.config.site.reviewsfooter) {
    let footer = dompack.qS(".footer > .ww-grid");

    let element = document.createElement("div");
    element.classList.add("footerreviews");
    element.innerText = whintegration.config.site.reviewsfooter;
    footer.appendChild(element);
  }
});
