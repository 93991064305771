/* global $ */
import './ctacarousel.scss';

import * as dompack from 'dompack';

dompack.onDomReady(() => {
  const carousels = dompack.qSA('.w-ctacarousel');

  for (const carousel of carousels) {
    const carouselRow = carousel.querySelector('.w-ctacarousel__cols');
    const ctaWidth = document.querySelector(".w-ctacarousel__col").offsetWidth;
    fillRow(carouselRow);

    const carouselChildren = [...carouselRow.children];
    let isDragging = false, dragCheck = false;
    let startX, startScrollLeft;
    let delta = 6;
    let href, imglink;

    //start and stop dragging
    const dragStart = (e) => {
      if (e.button !== 0) return;

      isDragging = true;
      startX = e.pageX;
      startScrollLeft = carouselRow.scrollLeft;

      carouselRow.classList.add('dragging');

      //assign element which has the href to imglink
      if (e.target.parentElement.classList.contains('w-ctacarousel__imagelink')) {
        imglink = e.target.parentElement;
      }
      else {
        imglink = e.target;
      }

      href = imglink.href;
    };
    const dragStop = () => {
      isDragging = false;
      carouselRow.classList.remove('dragging');

      //add achor link after dragging
      setTimeout(() => {  
        if (imglink) imglink.setAttribute('href', href);
        dragCheck = false;
      },  300);
    };
    const dragging = (e) => {
      if (!isDragging) return;
      carouselRow.scrollLeft = startScrollLeft - (e.pageX - startX);

      //remove anchor link when dragged
      if (e.pageX - startX > delta || e.pageX - startX < -delta) {
        imglink.removeAttribute('href');
        dragCheck = true;
      }
    };

    //snaps to beginning or end based on scrollposition
    const infiniteScroll = () => {
      if (carouselRow.scrollLeft === 0) {
          carouselRow.classList.add("no-smoothing");
          carouselRow.scrollLeft = carouselRow.scrollWidth - (2 * carouselRow.offsetWidth);
          carouselRow.classList.remove("no-smoothing");
      } 
      else if (Math.ceil(carouselRow.scrollLeft) === carouselRow.scrollWidth - carouselRow.offsetWidth) {
          carouselRow.classList.add("no-smoothing");
          carouselRow.scrollLeft = carouselRow.offsetWidth;
          carouselRow.classList.remove("no-smoothing");
      }
    };

    //add a full view (amount) of ctas before and after for seamless snapping
    let ctasPerView = Math.round(carouselRow.offsetWidth / ctaWidth);
    carouselChildren.slice(-ctasPerView).reverse().forEach(cta => {
      carouselRow.insertAdjacentHTML("afterbegin", cta.outerHTML);
    });
    carouselChildren.slice(0, ctasPerView).forEach(cta => {
      carouselRow.insertAdjacentHTML("beforeend", cta.outerHTML);
    });

    //dragging listeners
    carouselRow.addEventListener("mousemove", dragging);
    carouselRow.addEventListener("mousedown", dragStart);
    document.addEventListener("mouseup", dragStop);
    carouselRow.addEventListener("scroll", infiniteScroll);

    //scroll using buttons
    const actions = carousel.querySelectorAll('.w-ctacarousel__action');
    actions.forEach(btn => {
        btn.addEventListener("click", () => {
            carouselRow.scrollLeft += btn.id === "prev" ? -ctaWidth : ctaWidth;
        })
    });

    //add onclick listener to popup cta's
    const popupListener = carousel.querySelectorAll('.w-ctacarousel__popup--listener');
    popupListener.forEach((el) => 
      el.addEventListener('click', () => {popupHandler(el, dragCheck)})
    );

    //add onclick toggle on mobile to hover cta's
    const hoverToggle = carousel.querySelectorAll('.w-ctacarousel__hover--container');
    hoverToggle.forEach((el) => 
      el.addEventListener('touchstart', () => {toggleHandler(el)})
    );
  }
});

function popupHandler (el, check) {
  if (check) return;
  //create popup elements
  const popupBackdrop = document.createElement('div');
  const popup = document.createElement('div');
  const content = el.firstChild.cloneNode(true);
  const backButtonContainer = document.createElement('div');
  const backButton = document.createElement('div');

  //insert popupelements in body and assign classes
  backButtonContainer.appendChild(backButton);
  backButton.setAttribute("class", "w-ctacarousel__popup--item");
  backButton.innerHTML = '<svg id="popup-backbutton" style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>window-close</title><path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z" /></svg>';
  backButton.addEventListener('click', () => {closePopupHandler()});

  popup.appendChild(backButtonContainer)
  backButtonContainer.setAttribute("class", "w-ctacarousel__popup--btncontainer w-ctacarousel__popup--item")

  popup.appendChild(content);
  content.setAttribute("class", "w-ctacarousel__popup--content")
  content.setAttribute("style", "display: block;")

  popupBackdrop.appendChild(popup);
  popup.setAttribute("class", "w-ctacarousel__popup w-ctacarousel__popup--item");
  popup.addEventListener('click', (e) => {e.stopPropagation();})

  document.body.appendChild(popupBackdrop)
  popupBackdrop.setAttribute("class", "w-ctacarousel__popup--backdrop w-ctacarousel__popup--item");
  popupBackdrop.addEventListener('click', () => { closePopupHandler() });
}

function closePopupHandler () {
  //delete popupelements
  const popup = document.getElementsByClassName("w-ctacarousel__popup--item");

  for (let i = popup.length - 1; i >= 0; i--) {
    popup[i].remove();
  }
}

function toggleHandler (el) {
  const content = el.querySelector('.w-ctacarousel__hover--content');

  if (content.style.maxHeight != '300px') {
    content.style.maxHeight = '300px';
  } else {
    content.style.maxHeight = '0px';
  }
}

function fillRow (el) {
  const children = [...el.children];

  //fill slider row until view amount
  for (i = [...el.children].length; i < Math.round(el.offsetWidth / el.firstChild.offsetWidth); i += children.length) {
    children.forEach((child) => {
      clone = child.cloneNode(true);
      el.appendChild(clone);
    });
  }
}